import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import { getLanguageLists } from '../utils/language';
import SelectLanguage from '../components/Language/SelectLanguage';
import LanguageOverviewCard from '../components/Language/LanguageOverviewCard';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import HelloGif from '../images/gif/Hello-pl_2.gif';

const LanguagePage = (props) => {
  const {
    data: {
      bgContact,
      prismicNonEngEpisodes,
      allPrismicLanguagePage,
      prismicNonEngEpisodesDubbed,
      prismicNonEngEpisodesSubtitles,
      language: { nodes: languages, totalCount },
    },
  } = props;
  const dubbedLanguageLists = getLanguageLists(languages, prismicNonEngEpisodesDubbed.nodes);
  const subbedLanguageLists = getLanguageLists(languages, prismicNonEngEpisodesSubtitles.nodes);

  const comingSoonLanguages = languages.filter((language) => {
    return (
      language.data.status === 'Coming Soon' ||
      (!dubbedLanguageLists.some(
        (dubbedLanguage) => dubbedLanguage.data.title[0].text === language.data.title[0].text
      ) &&
        !subbedLanguageLists.some((subLanguage) => subLanguage.data.title[0].text === language.data.title[0].text))
    );
  });

  const prismicLanguagePageData = allPrismicLanguagePage?.nodes[0]?.data;

  const pageTitle = prismicLanguagePageData?.pageTitle[0]?.text;
  const contactUsDescription = prismicLanguagePageData?.contactUsDescription[0]?.text;
  const pageDescription = prismicLanguagePageData?.pageDescription[0]?.text;
  const totalNumOfVolunteers = prismicLanguagePageData?.totalNumOfVolunteers;

  const languageStatistics = [
    {
      title: 'Subtitles Episodes',
      number: prismicNonEngEpisodesSubtitles.totalCount,
    },
    {
      title: 'Dubbed Episodes',
      number: prismicNonEngEpisodesDubbed.totalCount,
    },
    {
      title: 'Total languages',
      number: totalCount,
    },
    {
      title: 'number of volunteers',
      number: `${totalNumOfVolunteers[0].text}`,
    },
  ];

  return (
    <BodyClassName className="body-light page-language">
      <DefaultLayoutComponent title="Language">
        <div className="site-main">
          <BackgroundImage
            className="page-banner bgImage"
            fluid={bgContact.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-md pb-container">
              <h3 className="pb-title">{pageTitle}</h3>
              <div className="pb-content">
                <p className="p-lg-md">{pageDescription}</p>
              </div>
            </div>
          </BackgroundImage>
          <section className="language-container bg-dark pd-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section bg-light">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="section-header">
                    <div className="">
                      <div className="section-header-p">
                        <p className="p-md">{contactUsDescription}</p>
                        <Link to="/contact/" className="btn btn-md btn-primary-ii pb-link contact-button">
                          Contact
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <img src={HelloGif} style={{ width: '100%' }} />
                    </div>
                  </div>
                  <div className="section-body">
                    <div className="text">
                      <h5>Dubbed Episodes</h5>
                    </div>
                    <div className="languages-list">
                      {dubbedLanguageLists.map((items, index) => (
                        <SelectLanguage title={items} key={index} category={'dub'} />
                      ))}
                    </div>
                    <div className="text">
                      <h5>Subtitled Episodes</h5>
                    </div>
                    <div className="languages-list">
                      {subbedLanguageLists.map((items, index) => (
                        <SelectLanguage title={items} key={index} category={'sub'} />
                      ))}
                    </div>

                    <div className="text">
                      <h5>Check back later for these languages:</h5>
                    </div>

                    <div className="languages-list">
                      {comingSoonLanguages.map((items, index) => (
                        <SelectLanguage title={items} key={index} isComingSoon={true} category={'sub'} />
                      ))}
                    </div>

                    <div className="section-body-statistics">
                      {languageStatistics.map((items, index) => (
                        <LanguageOverviewCard title={items.title} number={items.number} key={index} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    prismicNonEngEpisodes: allPrismicEpisodes(filter: { data: { language: { ne: "English" } } }) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              slug
            }
          }
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
          videoDuration: video_duration {
            text
          }
          language
        }
        slugs
      }
    }

    allPrismicLanguagePage {
      nodes {
        data {
          contactUsDescription: contact_us_description {
            text
          }
          pageDescription: page_description {
            text
          }
          pageTitle: page_title {
            text
          }
          totalNumOfVolunteers: total_number_of_volunteers1 {
            text
          }
        }
      }
    }

    language: allPrismicCategories(
      filter: { data: { non_english_language: { eq: "Yes" } } }
      sort: { fields: data___priority }
    ) {
      nodes {
        data {
          languageCode: language_code {
            text
          }
          flagCode: flag_code {
            text
          }
          priority
          title {
            text
          }
          foreignLanguageName: foreign_language_name {
            text
          }
          status
        }
        uid
      }
      totalCount
    }
    prismicNonEngEpisodesDubbed: allPrismicEpisodes(filter: { data: { language: { eq: "Non English Dubbed" } } }) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              slug
              uid
            }
          }
        }
      }
      totalCount
    }
    prismicNonEngEpisodesSubtitles: allPrismicEpisodes(
      filter: { data: { language: { eq: "Non English Subtitles" } } }
    ) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              slug
              uid
            }
          }
        }
      }
      totalCount
    }
  }
`;

export default LanguagePage;
