import { Link } from 'gatsby';
import React from 'react';

const SelectLanguage = ({ title, category, isComingSoon }) => {
  const languageCode = title?.data?.languageCode[0]?.text.replace(/-/g, '');
  const flagCode = title?.data?.flagCode.length ? title?.data?.flagCode[0]?.text.replace(/-/g, '') : languageCode;
  const languageTitle = title?.data?.title[0].text;
  const foreignLanguageTitle = title?.data?.foreignLanguageName[0].text;
  const viewLink = `/language/${category}/${languageCode}/`;
  const countryImageUrl = `https://flagcdn.com/h24/${flagCode}.png`;

  return (
    <div className="selectLanguage-body" style={{ backgroundColor: isComingSoon ? '#F4F5F7' : '' }}>
      <Link to={viewLink} className="cat-link" style={{ pointerEvents: isComingSoon ? 'none' : 'auto' }}>
        {isComingSoon && (
          <div className="coming_soon">
            <span>Coming Soon</span>
          </div>
        )}
        <div className="div-language" style={{ marginTop: isComingSoon ? 0 : '20px', opacity: isComingSoon ? 0.3 : 1 }}>
          <div>
            <img src={countryImageUrl} />
          </div>
          <div class="language-title-container" style={{}}>
            <h6 className="native-language-title">{languageTitle}</h6>
            <h4 className="foreign-language-title">{foreignLanguageTitle}</h4>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SelectLanguage;
