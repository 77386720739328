import React from 'react';

const LanguageOverviewCard = ({ title, number }) => (
  <div className="statistics-items">
    <div className="statistics-inner-item">
      <h6>{title}</h6>
      <h4>{number}</h4>
    </div>
  </div>
);

export default LanguageOverviewCard;
